<nz-layout>
    <nz-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" class="my-patients-content">
            <div fxFlex="25" fxFlex.lt-md="100" fxLayout="col" class="patient-list">
                <nz-layout class="content-layout">
                    <nz-content class="content-patients">
                        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center" class="search-bar">
                            <div fxLayout="column" fxFlex="75">
                                <input nz-input type="text" class="w-100 search-input" #searchInput>
                            </div>
                            <div fxLayout="column" fxFlex="25">
                                <button class="w-100 search-btn" (click)="pageChange(searchInput.value)">
                                    {{'Search' | translate}}</button>
                            </div>
                        </div>

                        <mat-table [dataSource]="data" class="mat-elevation-z8">

                            <ng-container matColumnDef="profile">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'Patients' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
                                        <div fxLayout="column">
                                            <nz-avatar [nzSize]="64" [nzSrc]="profilePicture[element?.id]"></nz-avatar>
                                        </div>

                                        <div fxLayout="column">
                                            <div fxLayout="row" fxFlex="100" class="patient-name">{{element?.firstName}}
                                                {{element?.lastName}}
                                            </div>
                                            <div fxLayout="row" fxFlex="100">{{element?.documentNumber}}</div>
                                        </div>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="age">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'Date of Birth' | translate }}
                                </mat-header-cell>
                                <mat-cell mat-cell *matCellDef="let element"> {{element.dateOfBirth || "-"}} </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="onPatientClick(row?.id)"></mat-row>
                        </mat-table>

                        <span>
                            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100"
                                *ngIf="data.data.length == 0" class="no-patients">
                                No Patients available.
                            </div>
                        </span>

                        <mat-paginator #paginator [pageSizeOptions]="getTableSize()" showFirstLastButtons
                            [ngClass]="data.data.length===0 ? 'patients-content-paginator-hide no-paginator' : ''"
                            (page)="pageChange()" [length]="totalRecords" />

                    </nz-content>
                </nz-layout>
            </div>
            <div fxFlex="75" fxFlex.lt-md="100" fxLayout="col" class="patient-details">
                <nz-layout class="content-layout">
                    <nz-content class="content-body" *ngIf="selectedPatientID && !isLoading">
                        <div class="details-container">
                            <ng-container>
                                <app-custom-table title="Medication" [data]="medications.getAll"
                                    [dataGetList]="medications" [isPreview]="true" [isLoading]="isLoading">

                                    <ng-container *ngFor="let medication of medications.getAll">
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <div class="medication-info">
                                                        <div><b>{{medication.productName}}</b></div>
                                                        <div class="package-details">
                                                            {{medication.productDescription}}
                                                        </div>
                                                        <div><i nz-icon nzType="icons:clock" nzTheme="outline"
                                                                class="icon clock-icon"></i>
                                                            {{medication.unitCombination}}
                                                            {{medication.frequencyCombination}} {{"for" | translate}}
                                                            {{medication.durationTreatmentCombination}}</div>
                                                        <div>
                                                            <i nz-icon nzType="icons:calendar-days" nzTheme="outline"
                                                                class="icon calendar-icon"></i>
                                                            {{"From" | translate}}
                                                            {{this.getDateTime(medication?.onSetDateTime)}} {{"to" |
                                                            translate}}
                                                            {{this.getDateTime(medication?.endDateTime)}}
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div>
                                                {{medication.assignedDoctorFullName}}
                                            </div>
                                            <div>
                                                {{medication.profession}}
                                            </div>
                                            <div>
                                                <span class="f-500">{{"Instructions to Patient" | translate}}:</span>
                                                {{medication.instructionPatient || "-"}}
                                            </div>
                                            <div>
                                                <span class="f-500">{{"Advice to Dispenser" | translate}}:</span>
                                                {{medication.adviceToDispenser || "-"}}
                                            </div>
                                        </mat-expansion-panel>

                                    </ng-container>
                                </app-custom-table>
                            </ng-container>



                            <ng-container>
                                <app-custom-table title="Allergies" [data]="allergies.getAll" [dataGetList]="allergies"
                                    [isPreview]="true" [isLoading]="isLoading">
                                    <ng-container *ngFor="let allergy of allergies.getAll">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <div class="allergy-info">
                                                        <div>
                                                            <b>{{allergy.allergy}}</b> / {{allergy.eventType}} /
                                                            {{allergy.allergyCategory}}
                                                        </div>
                                                        <div class="f-500">{{allergy.criticality}}</div>
                                                        <div class="f-500">{{allergy.allergyStatus}}</div>

                                                        <div class="allergy-description">{{allergy.description}}</div>
                                                    </div>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ng-container *ngFor="let reaction of allergy.reactionDto; let i = index">
                                                <div>
                                                    <div><u>{{"Reaction" | translate}} {{i+1}}</u></div>
                                                    <div>
                                                        <b>{{reaction.manifestation}}</b>
                                                    </div>
                                                    <div>
                                                        {{"From" | translate}} {{this.getDateTime(reaction?.onSetDate)}}
                                                        <span *ngIf="reaction?.endDate">{{"to " +
                                                            reaction?.endDate}}</span>
                                                    </div>
                                                    <div>
                                                        {{reaction.certainty}} / {{reaction.severity}}
                                                    </div>


                                                    <div class="reaction-description">
                                                        {{reaction.description}}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-expansion-panel>

                                    </ng-container>
                                </app-custom-table>
                            </ng-container>

                            <ng-container>
                                <app-custom-table title="Problems" [data]="problems.getAll" [dataGetList]="problems"
                                    [isPreview]="true" [isLoading]="isLoading">
                                    <ng-container *ngFor="let problem of problems.getAll">
                                        <div class="entry-info">
                                            <div><b>{{problem.problem}} - {{problem.problemName}}</b></div>
                                            <div class="f-500">{{problem.onSetDate}}</div>
                                            <div class="f-500">{{problem.statusCode}}</div>

                                            <div class="diagnosis-assertion">{{problem.diagnosisAssertion}} /
                                                {{problem.description}}</div>
                                            <div>{{problem.resolutionCircumstancesText}}</div>
                                        </div>
                                    </ng-container>
                                </app-custom-table>
                            </ng-container>

                        </div>
                    </nz-content>
                </nz-layout>
            </div>
        </div>

    </nz-content>
</nz-layout>
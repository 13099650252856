import { Component, Input, Type } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { MatIconModule } from '@angular/material/icon';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { Router } from '@angular/router';
import { AllergyService } from 'src/app/services/allergy.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import { ArterialBloodGasService } from 'src/app/services/arterialBloodGas.service';
import { GlasgowService } from 'src/app/services/glasgow.service';
import { CtParametersService } from 'src/app/services/ctparameters.service';
import { TubeCharacteristicsService } from 'src/app/services/tubeCharacteristics.service';
import { CapnographyService } from 'src/app/services/capnography.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { CarePlanService } from 'src/app/services/carePlan.service';
import { TravelHistoryService } from 'src/app/services/travelHistory.service';
import { DynamicService } from 'src/app/services/dynamic.service';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';
import { TableRow } from 'src/app/common/table-row.interface';
import { TooltipComponent } from '../../../../../common/tooltip/tooltip.component';

@Component({
  standalone: true,
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.css'],
  imports: [
    NzLayoutModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NgTemplateOutlet,
    TooltipComponent,
  ],
  providers: [NgModule],
})
export class CustomTableComponent<T extends TableRow> {
  @Input() noKnownComponent: Type<any> | null = null;
  @Input() addComponent: Type<any> | null = null;

  @Input() isLoading?: boolean = false;
  @Input() isNoKnownLoading?: boolean;
  @Input() title: string = '';
  @Input() data?: T[];
  @Input() dataGetList?: IGetList<T>;

  @Input() isPreview?: boolean;

  @Input() deleteFun?: Function;
  @Input() openModalFun?: Function;
  @Input() handleToggleFun?: Function;

  // DO NOT REMOVE ALLERGY, VACCINATION,... SERVICES!!!
  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly allergyService: AllergyService,
    private readonly vaccinationService: VaccinationService,
    private readonly medicalAlertService: MedicalAlertService,
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly procedureService: ProcedureService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly carePlanService: CarePlanService,
    private readonly socialHistoryService: SocialHistoryService,
    private readonly travelHistoryService: TravelHistoryService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly dynamicService: DynamicService,
    private readonly vitalSignService: VitalSignService,
    private readonly arterialBloodGasService: ArterialBloodGasService,
    private readonly glasgowService: GlasgowService,
    private readonly ctParametersService: CtParametersService,
    private readonly tubeCharacteristicsService: TubeCharacteristicsService,
    private readonly capnographyService: CapnographyService,
    private readonly translate: TranslateService
  ) {}

  handleToggle() {
    this.handleToggleFun?.(
      this.isPreview,
      !this.dataGetList?.noKnown,
      this.dataGetList?.getUnknown?.id?.toString()!
    );
  }

  getUnknownText(): string {
    if (this.dataGetList?.noInfo || this.dataGetList?.noKnown) {
      for (const key in this.dataGetList?.getUnknown) {
        if (key !== 'id') {
          return this.dataGetList?.getUnknown[key];
        }
      }
    }

    return 'No data available';
  }

  openModal(item?: T) {
    this?.openModalFun?.(item);
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
}

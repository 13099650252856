<div *ngIf="isNewVisitLoading" class="loading-container">
  <div class="loading-text">
    <p>{{ "Loading..." | translate }}</p>
  </div>
  <mat-spinner></mat-spinner>
</div>

<!-- Your table or other content -->
<div class="patients-container">
  <div class="patients-search">
    <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
      <nz-select class="building" nzShowSearch nzAllowClear [nzPlaceHolder]="'Building' | translate"
        [(ngModel)]="selectedBuilding" [nzLoading]="isLoading" (ngModelChange)="onBuildingChange($event)">
        <ng-container *ngFor="let et of ddBuildings">
          <nz-option [nzLabel]="et.name" [nzValue]="et"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
      <nz-select class="floor" nzShowSearch nzAllowClear [nzPlaceHolder]="'Floor' | translate"
        [(ngModel)]="selectedFloor" [nzLoading]="isLoading" (ngModelChange)="onFloorChange($event)">
        <ng-container *ngFor="let et of ddFloors">
          <nz-option [nzLabel]="et.name" [nzValue]="et"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
      <nz-select class="ward" nzShowSearch nzAllowClear [nzPlaceHolder]="'Ward' | translate" [(ngModel)]="selectedWard"
        [nzLoading]="isLoading" (ngModelChange)="onWardChange($event)">
        <ng-container *ngFor="let et of ddWards">
          <nz-option [nzLabel]="et.name" [nzValue]="et"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
      <nz-select class="rooms" nzShowSearch nzAllowClear [nzPlaceHolder]="'Room' | translate" [(ngModel)]="selectedRoom"
        [nzLoading]="isLoading" (ngModelChange)="onRoomChange($event)">
        <ng-container *ngFor="let et of ddRooms">
          <nz-option [nzLabel]="et.name" [nzValue]="et"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input"></div>
  </div>
  <div class="patients-table">
    <mat-table [dataSource]="data" class="mat-elevation-z8">
      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Location" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- {{element.buildingName}}
                      {{element.floorName ? " | " + element.floorName : ""}} -->
          {{ element.wardName }}
          {{ element.roomNumber ? " | " + element.roomNumber : "" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="bedNumber">
        <mat-header-cell *matHeaderCellDef>
          {{ "Bed" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.bedNumber || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="patientFullName">
        <mat-header-cell *matHeaderCellDef>
          {{ "Patient" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.inPatientInfo?.patientFullName || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="patientAge">
        <mat-header-cell *matHeaderCellDef>
          {{ "Age" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.inPatientInfo?.patientAge || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lengthOfStay">
        <mat-header-cell *matHeaderCellDef>
          {{ "Length of stay (Days)" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.inPatientInfo?.lengthOfStay || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sepsis">
        <mat-header-cell *matHeaderCellDef>
          {{ "Sepsis" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <ng-container *ngIf="
              getRow(element)?.sepsis != undefined &&
              getRow(element)?.sepsis >= 0 &&
              getRow(element)?.sepsis >= 0
            ">
            <span [ngClass]="
                getRow(element)?.sepsis == 0
                  ? 'low-risk'
                  : getRow(element)?.sepsis == 1
                  ? 'mild-risk'
                  : getRow(element)?.sepsis == 2
                  ? 'moderate-risk'
                  : 'high-risk'
              ">
              {{
              getRow(element)?.sepsis === 0
              ? ("Low" | translate)
              : getRow(element)?.sepsis === 1
              ? ("Mild" | translate)
              : getRow(element)?.sepsis === 2
              ? ("Moderate" | translate)
              : ("High" | translate)
              }}</span>
            <!-- <span style="width: 45px;font-weight: 500;">
                              {{ getRow(element)?.sepsis + "%" }}</span>
                          <nz-progress [nzPercent]="getRow(element)?.sepsis" [nzStrokeColor]="getRow(element)?.sepsis < 33 ? '#317D88' : getRow(element)?.sepsis < 66 ? '#FFB843' : '
                              #E35052'" [nzShowInfo]="false" [ngClass]="getRow(element)?.sepsis < 33 ? 'green' : getRow(element)?.sepsis < 66 ? 'yellow' : '
                              red'"></nz-progress> -->
          </ng-container>
          <ng-container *ngIf="getRow(element)?.sepsis == undefined">
            -
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="saps2">
        <mat-header-cell *matHeaderCellDef>
          {{ "SAPS II" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">{{ getRow(element)?.saps2 || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="apache2">
        <mat-header-cell *matHeaderCellDef>
          {{ "Apache II" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ getRow(element)?.apache2 || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="apache4">
        <mat-header-cell *matHeaderCellDef>
          {{ "Apache IV" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ getRow(element)?.apache4 || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sofa">
        <mat-header-cell *matHeaderCellDef>
          {{ "Sofa" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ getRow(element)?.sofa || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ards">
        <mat-header-cell *matHeaderCellDef>
          {{ "ARDS" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <span style="text-align: center; width: 100%" [ngClass]="
              getRow(element)?.ards > ardsRV.max!
                ? 'high-risk'
                : getRow(element)?.ards > ardsRV.mid!
                ? 'moderate-risk'
                : ''
            ">
            {{ getRow(element)?.ards || "-" }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="mews">
        <mat-header-cell *matHeaderCellDef>
          {{ "Mews" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <span style="text-align: center; width: 100%" [ngClass]="
              getRow(element)?.mews > mewsRV.max!
                ? 'high-risk'
                : getRow(element)?.mews > mewsRV.mid!
                ? 'moderate-risk'
                : ''
            ">
            {{ getRow(element)?.mews || "-" }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="glasgow">
        <mat-header-cell *matHeaderCellDef>
          {{ "Glasgow" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <span style="text-align: center; width: 100%" [ngClass]="
              getRow(element)?.glasgow > glasgowScoreRV.max!
                ? 'high-risk'
                : getRow(element)?.glasgow > glasgowScoreRV.mid!
                ? 'moderate-risk'
                : ''
            ">
            {{ getRow(element)?.mews || "-" }}
          </span>
        </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="admissionDate">
                  <mat-header-cell *matHeaderCellDef>
                      {{ 'Admission date' | translate }}
                  </mat-header-cell>
                  <mat-cell mat-cell *matCellDef="let element"> {{element?.inPatientInfo?.admissionDate ?
                      (element?.inPatientInfo?.admissionDate | date:'dd/MM/yyyy') : "-" }} </mat-cell>
              </ng-container> -->
      <ng-container matColumnDef="inpatient-actions">
        <mat-header-cell *matHeaderCellDef>
          {{ "Actions" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <button *ngIf="element.bedIsAvailable" class="add-button" (click)="handleAdd(element)">
            {{ "Add" | translate }}
          </button>

          <button *ngIf="!element.bedIsAvailable && dischargeVisible" class="discharge-button"
            (click)="handleDischarge(element)">
            {{ "Discharge" | translate }}
          </button>

          <button *ngIf="!element.bedIsAvailable" class="view-button" [ngClass]="dischargeVisible ? '' : 'full-width'"
            (click)="handleView(element)">
            {{ "View" | translate }}
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <span>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100" *ngIf="data.data.length == 0 && !isLoading"
        class="no-patients">
        No Patients available.
      </div>
    </span>
    <!-- <div fxLayout="row" [ngClass]="data.data.length!==0 ? 'patients-content-paginator-hide' : ''">
          <div fxLayout="col" fxFlex="100" style="margin-top: 3em; justify-content: center;">Search for Patients!</div>
          </div> -->
    <mat-paginator #inpaginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [ngClass]="
        data.data.length === 0 ? 'patients-content-paginator-hide' : ''
      " (page)="searchPatientsForm()" [length]="totalRecords" />
  </div>

  <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" class="inPatient-spinner"><nz-spin
      nzSimple></nz-spin>
  </div>

</div>

<nz-modal nzNoAnimation #bedModalRef nzSize [(nzVisible)]="isAssignVisible"
  [nzTitle]="'Assign Patient to Bed' | translate" (nzOnCancel)="handleAssignCancel()" nzCancelText="Cancel"
  nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="bedForm" (ngSubmit)="submitBedForm()" [ngClass]="{
        'bed-submitted': bedSubmitted,
        'bed-not-submitted': !bedSubmitted
      }">
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Patient" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
          <nz-select class="patient-dropdown" nzShowSearch nzAllowClear [nzPlaceHolder]="'Patient' | translate"
            [nzLoading]="isAssignLoading" formControlName="patientValue" [nzOptions]="availablePatients" nzNoAnimation>
            <!-- <ng-container *ngFor="let et of availablePatients">
                              <nz-option [nzLabel]="et.fullName" [nzValue]="et.id"></nz-option>
                          </ng-container> -->
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Method of Admission" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
          <nz-select class="patient-dropdown" nzShowSearch nzAllowClear
            [nzPlaceHolder]="'Method of Admission' | translate" [nzLoading]="isDischargeLoading"
            formControlName="methodValue" [nzDisabled]="situationDisabled">
            <ng-container *ngFor="let et of reasons">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Method Notes" | translate
            }}<span class="optional">{{
              "(optional)" | translate
              }}</span></label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
          <textarea type="text" rows="2" nz-input class="inputs width-100"
            placeholder="{{ 'Method Notes' | translate }}" formControlName="noteValue"></textarea>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isAssignLoading"
          [disabled]="isAssignLoading">
          {{ "Save" | translate }}
        </button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleAssignCancel()"
          [disabled]="isAssignLoading">
          {{ "Cancel" | translate }}
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template>
    <button type="submit">{{ "Save" | translate }}</button>
  </ng-template>
</nz-modal>

<nz-modal nzNoAnimation #dischargeModalRef nzSize [(nzVisible)]="isDischargeVisible"
  [nzTitle]="'Discharge Patient' | translate" (nzOnCancel)="handleDischargeCancel()" nzCancelText="Cancel"
  nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="dischargeForm" (ngSubmit)="submitDischargeForm()" [ngClass]="{
        'discharge-submitted': dischargeSubmitted,
        'discharge-not-submitted': !dischargeSubmitted
      }">
      <div class="form-row" fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Final Diagnosis" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start start">
          <nz-select class="patient-dropdown" nzShowSearch nzAllowClear [nzPlaceHolder]="'Final Diagnosis' | translate"
            [nzLoading]="isDischargeLoading" formControlName="diagnosisValue" [nzOptions]="finalDiagnosis"
            nzNoAnimation>
            <!-- <ng-container *ngFor="let et of dischargeDropdown?.finalDiagnostic">
                              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                          </ng-container> -->
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Type of Discharge" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
          <nz-select class="patient-dropdown" nzShowSearch nzAllowClear
            [nzPlaceHolder]="'Type of Discharge ' | translate" [nzLoading]="isDischargeLoading"
            formControlName="dischargeTypeValue" (ngModelChange)="handleTypeChange($event)">
            <ng-container *ngFor="let et of dischargeDropdown?.typeOfDischarge">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Type Notes" | translate
            }}<span class="optional">{{
              "(optional)" | translate
              }}</span></label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start start">
          <textarea rows="2" type="text" nz-input class="inputs width-100" placeholder="{{ 'Type Notes' | translate }}"
            formControlName="dischargeTypeNotesValue"></textarea>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Situation" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
          <nz-select class="patient-dropdown" nzShowSearch nzAllowClear [nzPlaceHolder]="'Situation' | translate"
            [nzLoading]="isDischargeLoading" formControlName="dischargeSituationValue" [nzDisabled]="situationDisabled">
            <ng-container *ngFor="let et of dischargeDropdown?.situation">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
          <label>{{ "Situation Notes" | translate
            }}<span class="optional">{{
              "(optional)" | translate
              }}</span></label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
          <textarea rows="2" type="text" nz-input class="inputs width-100"
            placeholder="{{ 'Situation Notes' | translate }}" formControlName="dischargeSituationNotesValue"></textarea>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isDischargeLoading"
          [disabled]="isDischargeLoading">
          {{ "Discharge" | translate }}
        </button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleDischargeCancel()"
          [disabled]="isDischargeLoading">
          {{ "Cancel" | translate }}
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template>
    <button type="submit">{{ "Save" | translate }}</button>
  </ng-template>
</nz-modal>
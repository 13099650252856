<nz-layout>
    <nz-content>
        <ng-container>
            <app-reusable-table title="Pregnancy Status"
                [columns]="['Date of Observation','Status','Pregnancy Estimation','Pregnancy Estimation Date']"
                [rowFields]="['dateOfObservation','status','pregnancyEstimated','estimatedDate']"
                [data]="pregnancyStatus" [addComponent]="addComp" [isLoading]="isLoading" [deleteFun]="deleteFun"
                [openModalFun]="openModalFun" [isPreview]="false" />
        </ng-container>
    </nz-content>
</nz-layout>